import FinanceTable from '@/Finance/components/FinanceTable.vue';
import { defineComponent, computed } from '@vue/composition-api';
import { useFinanceHistoryQuery } from '@/generated-types/graphql.types';
import i18n from '@/i18n/i18n-setup';
export default defineComponent({
    components: {
        FinanceTable
    },
    setup() {
        const { result, loading } = useFinanceHistoryQuery();
        const allUserFinanceHistory = computed(() => {
            const history = result?.value?.all_finance_history;
            return history?.map(item => {
                return {
                    status: item?.payout_status,
                    booking_id: item?.booking_id,
                    invoice_id: item?.invoice_id,
                    payout_amount: item?.payout_amount,
                    ts_created: item?.ts_created,
                    document: item?.invoice_type
                };
            });
        });
        const columns = computed(() => {
            return [
                {
                    name: 'ts_created',
                    label: i18n.t('finance.history.table.columns.created'),
                    sortable: true,
                    customElement: 'date'
                },
                {
                    name: 'document',
                    label: i18n.t('finance.history.table.columns.document'),
                    sortable: true,
                    customElement: 'document'
                },
                {
                    name: 'invoice_id',
                    label: i18n.t('finance.history.table.columns.invoice'),
                    sortable: true
                },
                {
                    name: 'booking_id',
                    label: i18n.t('finance.history.table.columns.booking'),
                    sortable: true,
                    customElement: 'booking'
                },
                {
                    name: 'payout_amount',
                    label: i18n.t('finance.history.table.columns.amount'),
                    sortable: true,
                    customElement: 'amount'
                },
                {
                    name: 'status',
                    label: i18n.t('finance.history.table.columns.status'),
                    sortable: true,
                    customElement: 'status'
                },
                {
                    name: 'actions',
                    label: i18n.t('finance.history.table.columns.resend_invoice'),
                    sortable: false,
                    customElement: 'actions'
                }
            ];
        });
        return {
            columns,
            data: allUserFinanceHistory,
            loading
        };
    }
});
